import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "gatsby-link"

const Wrapper = styled.div`
  padding: 2rem;
`
const StyledLink = styled(Link)`
  color: black;
`
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>404: NOT FOUND</h1>
      <StyledLink to="/">
        <p>Takaisin etusivulle </p>
      </StyledLink>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
